<template>
  <div class="editor-canvas">
    <canvas :id="`canvas-editor-${index}`" class=""></canvas>
    <el-dialog title="" :visible.sync="textDialogVisible" width="400px">
      <div>
        <el-input
          v-model="textValue"
          :rows="5"
          type="textarea"
          placeholder=""
        ></el-input>
      </div>
      <div slot="footer">
        <el-button size="mini" @click="textDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="changeText"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { fabric } from "fabric";
import { throttle, getOssFileInfo } from "@/utils/util";
const edgeImg = require("@/assets/edgecontrol.svg");
const rotateImg = require("@/assets/rotateicon.svg");
const verticalImg = require("@/assets/middlecontrol.svg");
const horizontalImg = require("@/assets/middlecontrolhoz.svg");
const scalecontrolImg = require("@/assets/scalecontrol.svg");
// 保存原始的 getObjects 方法
const originalGetObjects = fabric.Canvas.prototype.getObjects;
// 重写 getObjects 方法
fabric.Canvas.prototype.getObjects = function (type) {
  if (typeof type === "undefined") {
    return this._objects.concat();
  }
  return this._objects.filter(function (o) {
    if (type.indexOf(".") > -1) {
      return o.name === type.split(".")[1];
    } else {
      return o.type === type;
    }
  });
};

fabric.Canvas.prototype.getObject = function (val) {
  if (typeof val === "undefined") {
    return this._objects.concat();
  }
  return this._objects.filter(function (o) {
    if (val.indexOf("#") > -1) {
      if (`#${o.id}` == val) {
        return o;
      }
    }
  });
};

export default {
  props: {
    value: {
      type: Object,
    },
    index: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
    top: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      workspaceEl: null,
      canvas: null,
      workspace: null,
      zoomRatio: 0.7,

      marginLeft: 0,
      marginTop: 0,

      // 用于保存历史记录的栈
      stateStack: [],
      redoStack: [],

      textDialogVisible: false,

      textValue: "",
    };
  },

  watch: {
    zoomRatio: {
      handler(val) {
        this.$emit("zoomChange", val);
      },
    },
  },
  methods: {
    // 初始化背景
    initBackground() {
      this.canvas.backgroundImage = "";
      this.canvas.setWidth(this.workspaceEl.offsetWidth);
      this.canvas.setHeight(this.workspaceEl.offsetHeight);
    },

    // 初始化画布
    initWorkspace() {
      const workspace = new fabric.Rect({
        fill: "",
        width: this.width,
        height: this.height,
        id: "workspace",
        strokeWidth: 0,
      });
      workspace.set("selectable", false);
      workspace.set("hasControls", false);
      workspace.hoverCursor = "default";
      this.canvas.add(workspace);
      this.canvas.renderAll();
      this.workspace = workspace;
      this.auto();
      this.$emit("confirm", this.canvas);
    },

    // 自动缩放
    auto() {
      const scale = this._getScale();
      this.zoomRatio = 1;
      this.setZoomAuto(this.zoomRatio);
    },

    _getScale() {
      return fabric.util.findScaleToFit(this.getWorkspase(), {
        width: this.workspaceEl.offsetWidth,
        height: this.workspaceEl.offsetHeight,
      });
    },

    // 放大
    big() {
      let zoomRatio = this.canvas.getZoom();
      zoomRatio += 0.05;
      const center = this.canvas.getCenter();
      this.zoomRatio = zoomRatio;
      this.canvas.zoomToPoint(
        new fabric.Point(center.left, center.top),
        zoomRatio
      );
    },

    // 缩小
    small() {
      let zoomRatio = this.canvas.getZoom();
      zoomRatio -= 0.05;
      this.zoomRatio = zoomRatio;
      const center = this.canvas.getCenter();
      this.canvas.zoomToPoint(
        new fabric.Point(center.left, center.top),
        zoomRatio < 0 ? 0.01 : zoomRatio
      );
    },

    setZoomAuto(scale) {
      const { workspaceEl } = this;
      const width = workspaceEl.offsetWidth;
      const height = workspaceEl.offsetHeight;
      this.canvas.setWidth(width);
      this.canvas.setHeight(height);
      const center = this.canvas.getCenter();
      this.canvas.setViewportTransform(fabric.iMatrix.concat());
      this.canvas.zoomToPoint(new fabric.Point(center.left, center.top), scale);
      if (!this.workspace) return;
      this.setCenterFromObject(this.workspace);
      // 超出画布不展示
      this.workspace.clone((cloned) => {
        this.canvas.clipPath = cloned;
        this.canvas.requestRenderAll();
      });
    },

    /**
     * 设置画布中心到指定对象中心点上
     * @param {Object} obj 指定的对象
     */
    setCenterFromObject(obj) {
      const { canvas } = this;
      const objCenter = obj.getCenterPoint();
      const viewportTransform = canvas.viewportTransform;
      if (
        canvas.width === undefined ||
        canvas.height === undefined ||
        !viewportTransform
      ) {
        return;
      }
      viewportTransform[4] =
        canvas.width / 2 - objCenter.x * viewportTransform[0];
      viewportTransform[5] =
        canvas.height / 2 - objCenter.y * viewportTransform[3];
      canvas.setViewportTransform(viewportTransform);
      canvas.renderAll();
    },

    // 返回workspace对象
    getWorkspase() {
      return this.canvas.getObjects().find((item) => item.id === "workspace");
    },

    // 初始化监听器
    handleResize() {
      setTimeout(() => {
        this.auto();
        this.$emit("zoomChange", this.zoomRatio);
      }, 100);
    },

    initControl() {
      // 选中样式
      fabric.Object.prototype.set({
        transparentCorners: false,
        borderColor: "#51B9F9",
        cornerColor: "#FFF",
        borderScaleFactor: 2.5,
        cornerStyle: "circle",
        cornerStrokeColor: "#0E98FC",
        borderOpacityWhenMoving: 1,
      });
      this.peakControl();
      // 中间横杠图标
      this.intervalControl();
      //
      this.scaleControl();
      // 旋转图标
      this.rotationControl();
      //
      this.deleteControl();
    },

    peakControl() {
      const that = this;
      const img = document.createElement("img");
      img.src = edgeImg;

      function renderIconEdge(ctx, left, top, styleOverride, fabricObject) {
        that.drawImg(ctx, left, top, img, 25, 25, fabricObject.angle);
      }
      // 四角图标
      fabric.Object.prototype.controls.tl = new fabric.Control({
        x: -0.5,
        y: -0.5,
        cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
        actionHandler: fabric.controlsUtils.scalingEqually,
        render: renderIconEdge,
      });
      fabric.Object.prototype.controls.bl = new fabric.Control({
        x: -0.5,
        y: 0.5,
        cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
        actionHandler: fabric.controlsUtils.scalingEqually,
        render: renderIconEdge,
      });
      fabric.Object.prototype.controls.tr = new fabric.Control({
        x: 0.5,
        y: -0.5,
        cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
        actionHandler: fabric.controlsUtils.scalingEqually,
        render: renderIconEdge,
      });
      // fabric.Object.prototype.controls.br = new fabric.Control({
      //   x: 0.5,
      //   y: 0.5,
      //   cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
      //   actionHandler: fabric.controlsUtils.scalingEqually,
      //   render: renderIconEdge,
      // });
    },

    drawImg(ctx, left, top, img, wSize, hSize, angle) {
      if (angle === undefined) return;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(angle));
      ctx.drawImage(img, -wSize / 2, -hSize / 2, wSize, hSize);
      ctx.restore();
    },

    scaleControl() {
      const that = this;
      const scaleIcon = scalecontrolImg;
      const img = document.createElement("img");
      img.src = scaleIcon;
      function renderScaleIcon(ctx, left, top, styleOverride, fabricObject) {
        that.drawImg(ctx, left, top, img, 25, 25, fabricObject.angle);
      }
      fabric.Object.prototype.controls.scaleControl = new fabric.Control({
        x: 0.5,
        y: 0.5,
        offsetY: 0,
        offsetX: 0,
        cursorStyle: "pointer",
        cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
        actionHandler: fabric.controlsUtils.scalingEqually,
        render: renderScaleIcon,
      });
    },

    deleteControl() {
      const that = this;
      const deleteIcon =
        "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
      const delImg = document.createElement("img");
      delImg.src = deleteIcon;
      function renderDelIcon(ctx, left, top, styleOverride, fabricObject) {
        that.drawImg(ctx, left, top, delImg, 25, 25, fabricObject.angle);
      }
      // 删除选中元素

      fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0,
        y: 0.5,
        offsetY: 20,
        offsetX: 0,
        cursorStyle: "pointer",
        mouseUpHandler: function () {
          that.$emit("change", { func: "deleteObject" });
        },
        render: renderDelIcon,
      });
    },

    // 旋转控件
    rotationControl() {
      const that = this;
      const img = document.createElement("img");
      img.src = rotateImg;
      function renderIconRotate(ctx, left, top, styleOverride, fabricObject) {
        that.drawImg(ctx, left, top, img, 50, 50, fabricObject.angle);
      }
      // 旋转图标
      fabric.Object.prototype.controls.mtr = new fabric.Control({
        x: 0.5,
        y: 0,
        offsetY: 0,
        offsetX: 20,
        cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        // withConnecton: false,
        actionName: "rotate",
        render: renderIconRotate,
      });
    },

    intervalControl() {
      const that = this;
      const verticalImgIcon = document.createElement("img");
      verticalImgIcon.src = verticalImg;

      const horizontalImgIcon = document.createElement("img");
      horizontalImgIcon.src = horizontalImg;

      function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        that.drawImg(
          ctx,
          left,
          top,
          verticalImgIcon,
          20,
          25,
          fabricObject.angle
        );
      }

      function renderIconHoz(ctx, left, top, styleOverride, fabricObject) {
        that.drawImg(
          ctx,
          left,
          top,
          horizontalImgIcon,
          25,
          20,
          fabricObject.angle
        );
      }
      // 中间横杠
      fabric.Object.prototype.controls.ml = new fabric.Control({
        x: -0.5,
        y: 0,
        offsetX: -1,
        cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
        actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
        getActionName: fabric.controlsUtils.scaleOrSkewActionName,
        render: renderIcon,
      });

      fabric.Object.prototype.controls.mr = new fabric.Control({
        x: 0.5,
        y: 0,
        offsetX: 1,
        cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
        actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
        getActionName: fabric.controlsUtils.scaleOrSkewActionName,
        render: renderIcon,
      });

      fabric.Object.prototype.controls.mb = new fabric.Control({
        x: 0,
        y: 0.5,
        offsetY: 1,
        cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
        actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
        getActionName: fabric.controlsUtils.scaleOrSkewActionName,
        render: renderIconHoz,
      });

      fabric.Object.prototype.controls.mt = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetY: -1,
        cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
        actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
        getActionName: fabric.controlsUtils.scaleOrSkewActionName,
        render: renderIconHoz,
      });
    },

    async setOverlayImage(img = "") {
      return new Promise(async (resolve, reject) => {
        const mask = img ? img : this.value.mask;
        const OverlayImage = await getOssFileInfo({
          url: mask,
        });
        this.canvas.setOverlayImage(
          mask,
          this.canvas.renderAll.bind(this.canvas),
          {
            // 可选的选项，如定位等
            left: 0,
            top: 0,
            scaleX: this.width / OverlayImage.width,
            scaleY: this.height / OverlayImage.width,
            crossOrigin: "anonymous",
          }
        );
        setTimeout(() => {
          resolve(true);
        }, 100);
      });
    },

    // 删除
    deleteObject(obj) {
      if (obj.extraData.repeat && obj.extraData.repeat.show) {
        this.delRepeat();
      }
      this.canvas.remove(obj);
      this.workspace.clone((cloned) => {
        this.canvas.clipPath = cloned;
        this.canvas.requestRenderAll();
      });
      this.canvas.renderAll();
      this.$emit("refresh", true);
    },

    // 放大
    amplification(obj) {
      const scaleX = Number(obj.scaleX) + 0.1;
      const scaleY = Number(obj.scaleY) + 0.1;
      obj.rotate(0);
      obj.scaleX = scaleX;
      obj.scaleY = scaleY;
      const offsetX = (obj.width * 0.1) / 2;
      const offsetY = (obj.height * 0.1) / 2;
      obj.left = obj.left - offsetX;
      obj.top = obj.top - offsetY;
      this.canvas.requestRenderAll();
    },

    // 缩小
    narrow(obj) {
      const scaleX = Number(obj.scaleX) - 0.1;
      const scaleY = Number(obj.scaleY) - 0.1;
      obj.rotate(0);
      obj.scaleX = scaleX;
      obj.scaleY = scaleY;
      const offsetX = (obj.width * 0.1) / 2;
      const offsetY = (obj.height * 0.1) / 2;
      obj.left = obj.left + offsetX;
      obj.top = obj.top + offsetY;
      this.canvas.requestRenderAll();
    },

    // 铺满画布
    fullCanvas(obj) {
      let scale;
      let left = 0;
      let top = 0;
      obj.rotate(0);
      if (obj.height > obj.width) {
        scale = this.width / obj.width;
        top -= (obj.height * scale - this.width) / 2;
      } else {
        scale = this.width / obj.height;
        left -= (obj.width * scale - this.width) / 2;
      }
      obj.left = left;
      obj.top = top;
      obj.scale(scale);
      this.canvas.requestRenderAll();
    },

    // 铺满最大设计区
    fullDesign(obj) {
      let left = 0;
      let top = 0;
      if (!this.value.maxwidth || !this.value.maxheight) {
        this.fullCanvas(obj);
        return;
      }
      const scaleX = ((this.value.maxwidth / 800) * this.width) / obj.width;
      const scaleY = ((this.value.maxheight / 800) * this.width) / obj.height;
      obj.rotate(0);
      obj.scaleX = scaleX;
      obj.scaleY = scaleY;
      left -= (obj.width * scaleX - this.width) / 2;
      top -= (obj.height * scaleY - this.width) / 2;
      obj.left = left;
      obj.top = top;
      this.canvas.requestRenderAll();
    },

    // 铺满设计区
    fullDotted(obj) {
      let left = 0;
      let top = 0;
      const { tl_pointer, dottedwidth, dottedheight } = this.value;
      if (!dottedwidth || !dottedheight) {
        this.fullCanvas(obj);
        return;
      }
      let tlPointer = [];
      if (tl_pointer) {
        tlPointer = tl_pointer.split("|");
      }
      const scaleX = ((dottedwidth / 800) * this.width) / obj.width;
      const scaleY = ((dottedheight / 800) * this.width) / obj.height;
      obj.rotate(0);
      obj.scaleX = scaleX;
      obj.scaleY = scaleY;
      if (tlPointer.length > 0) {
        left = (tlPointer[0] / 800) * this.width;
        top = (tlPointer[1] / 800) * this.width;
      } else {
        left -= (obj.width * scaleX - this.width) / 2;
        top -= (obj.height * scaleY - this.width) / 2;
      }
      obj.left = left;
      obj.top = top;
      this.canvas.requestRenderAll();
    },

    // 宽度最大化
    maxWidth(obj) {
      let scale = this.width / obj.width;
      const { tl_pointer, dottedwidth, dottedheight } = this.value;
      let tlPointer = [];
      if (tl_pointer) {
        tlPointer = tl_pointer.split("|");
      }
      let left = 0;
      let top = 0;
      obj.rotate(0);
      if (dottedwidth) {
        scale = ((dottedwidth / 800) * this.width) / obj.width;
      }
      obj.scaleX = scale;
      obj.scaleY = scale;
      if (tlPointer.length > 0) {
        left = (tlPointer[0] / 800) * this.width;
        top =
          (tlPointer[1] / 800) * this.width +
          ((dottedheight / 800) * this.width - obj.height * scale) / 2;
      } else {
        left -= (obj.width * scale - this.width) / 2;
        top -= (obj.height * scale - this.width) / 2;
      }
      obj.left = left;
      obj.top = top;
      this.canvas.requestRenderAll();
    },

    // 高度最大化
    maxHeight(obj) {
      let scale = this.width / obj.height;
      const { tl_pointer, dottedwidth, dottedheight } = this.value;
      let tlPointer = [];
      if (tl_pointer) {
        tlPointer = tl_pointer.split("|");
      }
      let left = 0;
      let top = 0;
      if (dottedheight) {
        scale = ((dottedheight / 800) * this.width) / obj.height;
      }
      obj.rotate(0);
      obj.scaleX = scale;
      obj.scaleY = scale;
      if (tlPointer.length > 0) {
        left =
          (tlPointer[0] / 800) * this.width +
          ((dottedwidth / 800) * this.width - obj.width * scale) / 2;
        top = (tlPointer[1] / 800) * this.width;
      } else {
        left -= (obj.width * scale - this.width) / 2;
        top -= (obj.height * scale - this.width) / 2;
      }
      obj.left = left;
      obj.top = top;
      this.canvas.requestRenderAll();
    },

    // 居中
    centered(obj) {
      const width = obj.width * obj.scaleX;
      const height = obj.height * obj.scaleY;
      let left = 0;
      let top = 0;
      if (width > this.width) {
        this.width;
      } else {
        left += (this.width - width) / 2;
      }
      if (height > this.height) {
        top -= (height - this.height) / 2;
      } else {
        top += (this.height - height) / 2;
      }
      obj.left = left;
      obj.top = top;
      this.canvas.requestRenderAll();
    },

    // 水平翻转
    changeFlipHorizontal(obj) {
      obj.set("flipX", !obj.flipX).setCoords();
      this.canvas.requestRenderAll();
    },

    // 垂直翻转
    changeFlipVertical(obj) {
      obj.set("flipY", !obj.flipY).setCoords();
      this.canvas.requestRenderAll();
    },

    // 逆时针
    anticlockwise(obj) {
      let angle = obj.angle;
      if (angle == -360) {
        angle = 0;
      }
      obj.rotate(angle - 45);
      this.canvas.requestRenderAll();
    },
    // 顺时针
    clockwise(obj) {
      let angle = obj.angle;
      if (angle == 360) {
        angle = 0;
      }
      obj.rotate(angle + 45);
      this.canvas.requestRenderAll();
    },

    // 旋转
    changeRotate(obj, angle) {
      obj.rotate(angle);
      this.canvas.requestRenderAll();
    },

    changeFontFill(obj, value) {
      obj.fill = value;
      this.canvas.renderAll();
    },

    changeFontSize(obj, value) {
      obj.fontSize = value;
      this.canvas.renderAll();
    },

    changeTextAlign(obj, value) {
      obj.textAlign = value;
      this.canvas.renderAll();
    },

    //
    changeFontWeight(obj, value) {
      const nValue = obj.fontWeight === "normal" ? "bold" : "normal";
      obj.fontWeight = nValue;
      this.canvas.renderAll();
    },

    changefontStyle(obj, value) {
      const nValue = obj.fontStyle === "normal" ? "italic" : "normal";
      obj.fontStyle = nValue;
      this.canvas.renderAll();
    },

    //
    changeLineThrough(obj, value) {
      obj.linethrough = !obj.linethrough;
      this.canvas.renderAll();
    },

    // 下划线
    changeUnderline(obj, value) {
      obj.underline = !obj.underline;
      this.canvas.renderAll();
    },

    changestroke(obj, value) {
      obj.stroke = value;
      this.canvas.renderAll();
    },

    changestrokeWidth(obj, value) {
      obj.stroke = "red";
      obj.strokeWidth = value;
      this.canvas.renderAll();
    },

    // 横向文本
    changeTextHorizontal(obj, value) {
      obj.text = obj.extraData.name;
      obj.extraData.vertical = false;
      this.canvas.renderAll();
    },

    // 文本纵向排列
    changeTextVertical(obj, value) {
      if (obj.extraData.vertical) {
        return;
      }
      const chars = obj.text.split("");
      obj.text = chars.join("\n");
      obj.extraData.vertical = true;
      this.canvas.renderAll();
    },

    changeText() {
      const value = this.textValue;
      const obj = this.canvas.getActiveObject();
      if (obj.extraData.vertical) {
        const chars = value.split("");
        obj.text = chars.join("\n");
      } else {
        obj.text = value;
      }
      obj.extraData.name = value;
      obj.extraData.value = value;
      this.textDialogVisible = false;
      this.canvas.renderAll();
    },

    workspaceSendToBack() {
      const workspace = this.getWorkspace();
      workspace && workspace.sendToBack();
    },

    getWorkspace() {
      return this.canvas.getObjects().find((item) => item.id === "workspace");
    },

    // 上移
    layermoveup(obj) {
      obj.bringForward();
      this.canvas.setActiveObject(obj);
      this.workspaceSendToBack();
      this.canvas.requestRenderAll();
    },

    // 下移
    layermovedown(obj) {
      obj.sendBackwards();
      this.canvas.setActiveObject(obj);
      this.workspaceSendToBack();
      this.canvas.requestRenderAll();
    },

    // 删除平铺
    delRepeat() {
      return new Promise((resolve, reject) => {
        this.canvas.getObjects().forEach((item) => {
          if (item.extraData && item.extraData.repeat) {
            item.extraData.repeat = {
              show: false,
              src: "",
              type: 0,
              xpadding: 0,
              ypadding: 0,
            };
          }
          if (item.name == "repeat") {
            this.canvas.remove(item);
          }
        });
        this.canvas.requestRenderAll();
        resolve(true);
      });
    },

    // 平铺间距
    changeRepeat(obj, value) {
      return new Promise(async (resolve, reject) => {
        if (obj.type != "image") {
          resolve(true);
          return;
        }
        const { type, xpadding, ypadding } = value;
        await this.delRepeat();
        if (type == 0) {
          obj.extraData.repeat = {
            xpadding: 0,
            ypadding: 0,
            type: 0,
            show: false,
            src: "",
          };
          return;
        }
        obj.extraData.repeat = {
          xpadding: xpadding,
          ypadding: ypadding,
          type: type,
          show: true,
          src: "",
        };
        const angle = obj.angle;
        let scale = 1;
        let objAngle = 0;
        obj.rotate(0);
        // 计算中心点坐标
        const width = obj.width * obj.scaleX + xpadding * 2;
        const height = obj.height * obj.scaleY + ypadding * 2;
        let lBlock = Math.ceil(this.width / width);
        if (lBlock % 2 == 0) {
          lBlock += 1;
        }
        lBlock += 2;
        if (angle) {
          lBlock += 2;
        }
        const block = 2 * lBlock + 1;
        const repeatGroup = new fabric.Group([], {
          selectable: false,
          evented: false,
          name: "repeat",
          objectCaching: false,
        });
        obj.rotate(angle);
        let xBlock = block;
        let yBlock = block;
        if (type == 2) {
          xBlock += 1;
        }
        if (type == 3) {
          yBlock += 1;
        }
        const promises = [];
        for (let x = 0; x < xBlock; x++) {
          for (let y = 0; y < yBlock; y++) {
            let flipX = false;
            let flipY = false;
            let left = width * x;
            let top = height * y;
            if (type == 2) {
              if (y % 2 === 0) {
                left -= width / 2;
              }
            } else if (type == 3) {
              if (x % 2 === 0) {
                top -= height / 2;
              }
            } else if (type == 4) {
              if (x % 2 === 0) {
                flipX = true;
              }
              if (y % 2 === 0) {
                flipY = true;
              }
            } else if (type == 5) {
              const randomNum = Math.random() * (1.5 - 0.5) + 0.5;
              scale = Number(randomNum.toFixed(2));
              objAngle = Number(Math.floor(Math.random() * 361));
            }
            if (
              (y % 2 !== 0 && x == block && type == 2) ||
              (x % 2 !== 0 && y == block && type == 3)
            ) {
            } else {
              promises.push(
                this.cloneAndMirrorImage(
                  obj,
                  left,
                  top,
                  flipX,
                  flipY,
                  scale,
                  objAngle,
                  repeatGroup
                )
              );
            }
          }
        }
        Promise.all(promises).then(() => {
          if (angle) {
            repeatGroup.rotate(angle);
          }
          repeatGroup.left +=
            obj.getCenterPoint().x - repeatGroup.getCenterPoint().x;
          repeatGroup.top +=
            obj.getCenterPoint().y - repeatGroup.getCenterPoint().y;
          this.canvas.sendToBack(repeatGroup);
          this.workspaceSendToBack();
          this.canvas.renderAll();
          this.$emit("refresh");
          resolve(true);
        });
      });
    },

    cloneAndMirrorImage(
      img,
      left,
      top,
      isMirrorX,
      isMirrorY,
      scale = 1,
      angle = 0,
      group
    ) {
      return new Promise((resolve, reject) => {
        img.clone((clone) => {
          clone.set({
            left: left,
            top: top,
            angle: angle,
            evented: false,
            scaleX: img.scaleX * scale,
            scaleY: img.scaleY * scale,
          });
          if (isMirrorX) {
            clone.set({ flipX: !img.flipX });
          }
          if (isMirrorY) {
            clone.set({ flipY: !img.flipY });
          }

          group.addWithUpdate(clone);
          resolve(true);
        });
      });
    },

    async setObjLabelInfo(obj, saveHistory = true, refreshLayer = false) {
      this.canvas.renderAll();
      await this.calcImgWH(obj);
      const { tl, tr, bl, br } = obj.aCoords;
      const x1 = tl.x + this.left;
      const y1 = tl.y + this.top; // 对角线的一个端点
      const x2 = tr.x + this.left;
      const y2 = tr.y + this.top; // 另外一个顶点
      const x3 = br.x + this.left;
      const y3 = br.y + this.top; // 对角线的另一个端点
      const x4 = bl.x + this.left;
      const y4 = bl.y + this.top; // 另外一个顶点

      // 计算第一条对角线的斜率和截距
      const k1 = (y3 - y1) / (x3 - x1); // 斜率
      const b1 = y1 - k1 * x1; // 截距

      // 计算第二条对角线的斜率和截距
      const k2 = (y4 - y2) / (x4 - x2); // 斜率
      const b2 = y2 - k2 * x2; // 截距
      // 计算交点的坐标值
      const intersectX = (b2 - b1) / (k1 - k2); // 水平坐标值
      const intersectY = k1 * intersectX + b1; // 垂直坐标值
      const positionX = intersectX;
      const positionY = Math.min(...[y1, y2, y3, y4]);
      const imgLabel = document.querySelector(".img-wh-label");
      imgLabel.style.top = positionY - 45 + "px";
      imgLabel.style.left = positionX - imgLabel.offsetWidth / 2 - 10 + "px";
      this.resetControls(obj);
      this.getImageQuality(obj);
      if (saveHistory) {
        this.$emit("refresh", refreshLayer);
      }
    },

    resetControls(obj) {
      const workspaceLeft =
        this.canvas.getObject("#workspace")[0].lineCoords.tl.x;
      const workspaceTop =
        this.canvas.getObject("#workspace")[0].lineCoords.tl.y;
      const workspaceBottom =
        this.canvas.getObject("#workspace")[0].aCoords.br.y + workspaceTop;
      const workspaceRight =
        this.canvas.getObject("#workspace")[0].aCoords.br.x + workspaceLeft;
      const { mb, br } = obj.oCoords;
      const deleteControl = fabric.Object.prototype.controls.deleteControl;
      const scaleControl = fabric.Object.prototype.controls.scaleControl;
      if (obj.angle == 0 && mb.y > workspaceBottom - 40) {
        deleteControl.offsetY = -(mb.y - workspaceBottom + 40);
        scaleControl.offsetY = -(mb.y - workspaceBottom + 40);
      } else {
        deleteControl.offsetY = 20;
        scaleControl.offsetY = 0;
      }
      if (obj.angle == 0 && mb.x < workspaceLeft + 40) {
        deleteControl.offsetX = workspaceLeft - mb.x;
      } else if (mb.x > workspaceRight - 40) {
        deleteControl.offsetX = -(mb.x - workspaceRight + 40);
        scaleControl.offsetX = -(br.x - workspaceRight);
      } else {
        deleteControl.offsetX = 0;
      }
      if (obj.angle == 0 && br.x > workspaceRight) {
        scaleControl.offsetX = -(br.x - workspaceRight + 40);
      } else {
        scaleControl.offsetX = 0;
      }
      this.canvas.renderAll();
    },

    // 计算图片在实际生产中的大小
    calcImgWH(obj) {
      return new Promise((resolve, reject) => {
        const scale =
          (obj.width * obj.scaleX) /
          (obj.height * obj.scaleY) /
          (obj.width / obj.height);
        if (scale < 0.85) {
          if (scale < 0.5) {
            // this.$p.imgErrText = this.$p.$t("imagedeformed");
            // this.$p.imgErrScale = 2;
          } else {
            // this.$p.imgErrText = this.$p.$t("imageslightdeformed");
            // this.$p.imgErrScale = 1;
          }
          // this.$p.imgErrShow = true;
        } else if (scale > 1.15) {
          if (scale > 1.5) {
            // this.$p.imgErrText = this.$p.$t("imagedeformed");
            // this.$p.imgErrScale = 2;
          } else {
            // this.$p.imgErrText = this.$p.$t("imageslightdeformed");
            // this.$p.imgErrScale = 1;
          }
          // this.$p.imgErrShow = true;
        } else {
          // this.$p.imgErrShow = false;
        }
        const width = (
          ((obj.width * obj.scaleX) / this.width) *
          this.value.productionWidth
        ).toFixed(1);
        const height = (
          ((obj.height * obj.scaleY) / this.height) *
          this.value.productionHeight
        ).toFixed(1);
        this.$emit("change", {
          func: "setImgWH",
          value: { width: width, height: height },
        });
        resolve(true);
      });
      // this.$p.imgLabelShow = true;
    },

    layerShowOrHide(obj) {
      const value = !obj.extraData.show;
      if (!value) {
        obj.set("opacity", 0).setCoords();
        obj.set("hasControls", false);
        // obj.set("borderColor", "transparent");
        obj.evented = false;
        // this.$p.imgLabelShow = false;
        // this.showHideRepeat(obj.id, false);
        // this.canvas.setActiveObject(null);
      } else {
        obj.set("opacity", 1).setCoords();
        obj.set("hasControls", true);
        // obj.set("borderColor", this.$p.themeColor);
        obj.evented = true;
        // this.showHideRepeat(obj.id, true);
        this.canvas.setActiveObject(obj);
      }
      obj.extraData.show = value;
      this.canvas.renderAll();
    },

    layerLock(obj) {
      const value = !obj.extraData.lock;
      if (value) {
        obj.set("hasControls", false);
        // obj.set("borderColor", "transparent");
      } else {
        obj.set("hasControls", true);
        // obj.set("borderColor", this.$p.themeColor);
      }
      obj.evented = !value;
      obj.extraData.lock = value;
      this.canvas.renderAll();
    },

    setCanvasMask(obj, value) {
      var path = value.path;
      var clipPath = new fabric.Path(path, {
        scaleX: 3,
        scaleY: 3,
        originX: "center",
        originY: "center",
      });
      const canvasCenterX = this.width / 2;
      const canvasCenterY = this.height / 2;

      // 将 Path 对象的原点设置为其中心
      clipPath.set({
        left: canvasCenterX,
        top: canvasCenterY,
      });
      clipPath.setCoords();
      this.canvas.clipPath = clipPath;
    },

    setBackgroundColor(color) {
      this.canvas.setBackgroundColor(
        color,
        this.canvas.renderAll.bind(this.canvas)
      );
      setTimeout(() => {
        this.saveState();
      }, 50);
    },

    // 保存当前画布状态到历史记录栈中
    saveState() {
      this.redoStack = []; // 清空重做栈，因为新操作会重置 redo
      const json = this.canvas.toJSON(["extraData", "id", "name"]);
      this.stateStack.push(json);
    },

    // 撤销操作
    undo() {
      if (this.stateStack.length > 0) {
        this.redoStack.push(this.stateStack.pop());
        const previousState = this.stateStack[this.stateStack.length - 1];
        if (previousState) {
          this.canvas.loadFromJSON(previousState, () => {
            this.canvas.renderAll();
          });
        }
      }
    },

    // 重做操作
    redo() {
      if (this.redoStack.length > 0) {
        const state = this.redoStack.pop();
        this.stateStack.push(state);
        this.canvas.loadFromJSON(state, () => {
          this.canvas.renderAll();
        });
      }
    },

    clear() {
      this.canvas.getObjects(".customize").forEach((obj) => {
        if (obj.extraData.repeat.show) {
          this.delRepeat();
        }
        this.canvas.remove(obj);
      });
      this.canvas.requestRenderAll();
      this.$emit("refresh", true);
    },

    //获取图片打印质量
    getImageQuality(value) {
      let sizeArr = this.value["recommend_size"];
      let width =
        value.originScale > 0
          ? (value.originScale * value.width).toFixed(0)
          : value.width;

      if (sizeArr[0] > 0 && width / sizeArr[0] >= 1) {
        this.value.printquality = "high";
      } else {
        this.value.printquality = "low";
      }
    },

    // 快捷键
    hotkeyEvent(event) {
      const obj = this.canvas.getActiveObject();
      if (!obj) {
        return;
      }
      // 检查是否按下了Ctrl键
      if (event.ctrlKey && (event.key === "=" || event.key === "-")) {
        event.preventDefault();
      }
      if (event.ctrlKey && event.keyCode == 83) {
        // 快速保存
        event.preventDefault();
      }
      if (event) {
        if (event.ctrlKey) {
        } else if (event.altKey) {
          switch (event.keyCode) {
            case 87: // alt+w
              this.maxWidth(obj);

              break;
            case 72: // alt+h
              this.maxHeight(obj);
              break;
            case 82: // alt+r
              this.fullCanvas(obj);
              break;
            case 84: // alt+t
              this.fullDesign(obj);
            case 81: // alt+q
              this.fullDotted(obj);
              break;
            case 220: // alt+|
              this.changeFlipHorizontal(obj);
              break;
            case 191: // alt+/
              this.changeFlipVertical(obj);
              break;
            default:
              break;
          }
        } else {
          if ([46, 8].indexOf(event.keyCode) > -1) {
            this.deleteObject(obj);
            return;
          }
          switch (event.keyCode) {
            case 37: // 左箭头-37
              obj.left -= 1;
              break;
            case 38: // 上箭头-38
              obj.top -= 1;
              break;
            case 39: // 右箭头-39
              obj.left += 1;
              break;
            case 40: // 下箭头-40
              obj.top += 1;
              break;
            default:
              return;
          }
          this.canvas.renderAll();
        }
        this.setObjLabelInfo(obj);
      }
    },
  },

  async mounted() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("keydown", (event) => {
      this.hotkeyEvent(event);
    });
    const workspaceEl = document.querySelector("#workspace");
    if (!workspaceEl) {
      throw new Error("element #workspace is missing, plz check!");
    }
    this.workspaceEl = workspaceEl;
    this.canvas = new fabric.Canvas(`canvas-editor-${this.index}`, {
      fireRightClick: true, // 启用右键，button的数字为3
      stopContextMenu: true, // 禁止默认右键菜单
      controlsAboveOverlay: true, // 超出clipPath后仍然展示控制条
      imageSmoothingEnabled: false, // 解决文字导出后不清晰问题
      centeredRotation: true,
      preserveObjectStacking: true, // 禁止选中图层时自定置于顶部
      selection: false, // 禁止鼠标多选
    });
    // 监听对象选中事件
    this.canvas.on("selection:created", (e) => {
      this.$emit("change", { func: "selectChange" });
    });

    // 监听对象更新选中事件
    this.canvas.on("selection:updated", (e) => {
      this.$emit("change", { func: "selectChange" });
    });

    // 监听对象取消选中事件
    this.canvas.on("selection:cleared", () => {
      this.$emit("change", {
        func: "setImgWH",
        value: { width: "", height: "" },
      });
      // 这里可以添加处理逻辑，比如更新状态
    });
    // 移动事件
    this.canvas.on("object:moving", (e) => {
      this.setObjLabelInfo(e.target, false);
    });
    // 旋转事件
    this.canvas.on("object:rotating", (e) => {
      this.setObjLabelInfo(e.target, false);
    });
    this.canvas.on("object:modified", (e) => {
      this.$emit("change", { func: "change" });
      this.setObjLabelInfo(e.target);
    });
    this.canvas.on("object:added", (e) => {});
    let lastClickTime = 0;
    const doubleClickThreshold = 500; // 双击时间阈值，单位为毫秒

    this.canvas.on("mouse:down", (e) => {
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - lastClickTime;
      if (timeDiff < doubleClickThreshold) {
        if (e.target.type == "text") {
          this.textValue = e.target.text;
          this.textDialogVisible = true;
        }
      }

      lastClickTime = currentTime;
    });
    this.initBackground();
    this.initWorkspace();
    this.initControl();
    if (this.value.mask) {
      await this.setOverlayImage();
    }
    this.saveState();
  },
};
</script>
